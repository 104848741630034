import React from 'react';
import Layout from '../layouts/index';
import Hero from '../components/hero/Hero';
import HeroIllustration from '../components/hero/HeroIllustration';

export default function IndexPage() {
	return (
		<Layout>
			<Hero
				title="Growth your business with us"
				content="We provide design and software digital services that are fully-flexible and powerful. Order dedicated software for general IT, Window application, web application, mobile application development and more."
				illustration={HeroIllustration}
			/>
		</Layout>
	);
}
