import React, { Component } from 'react';
import FameLogo from '../../assets/images/fame_logo.jpg';

class FameCoffee extends Component {
    render() {
        return (
            <div style={{maxWidth:'70%',margin:'0 auto',marginTop:30}}> 
                <div>
                    <div style={{display:'flex'}}>
                    <img src={FameLogo} style={{width:100,margin:'0px auto'}} alt="Fame" />
                    </div>
                </div>
                <div>
                    <h5 style={{textAlign:'left'}}>PRIVACY POLICY</h5>
                    <p style={{fontSize:14,lineHeight:1.5}}>
                    Your privacy is important to us, we want you to understand our practices with regard to collecting and using your information and the steps we take to safeguard it. This statement applies to information that identifies a particular individual, such as name, postal address, e-mail address, credit card number, telephone number and any other data associated with an individual that may be used directly or indirectly to derive personal information.
                    </p>
                </div>
                <div>
                    <h5 style={{textAlign:'left'}}>Information We Collect</h5>
                    <p style={{fontSize:14,lineHeight:1.5}}>
                    We may collect personal information (including name, address, telephone number, email address and, when necessary, credit card information) when you place an order or create an account at our website. 
We may also collect some non-personal information about you. For instance, when you come to our site we may collect information about how you arrived at our site. This information may be used to track affiliate relationships to allow us to compensate our business partners for generating sales at this site.
                    </p>
                </div>
                <div>
                    <h5 style={{textAlign:'left'}}>Use of Data We Collect</h5>
                    <p style={{fontSize:14,lineHeight:1.5}}>
                    We use your personal information to allow us to process and track your order and to efficiently provide the services you have requested. We may use your information to contact you about the status of an order provide information concerning product recalls or products you have purchased.
                    </p>
                </div>
                <div>
                    <h5 style={{textAlign:'left'}}>Information We Share with Others</h5>
                    <p style={{fontSize:14,lineHeight:1.5}}>
                    In order to process your order we contract with other companies (credit card processors, shippers, website hosting companies) to provide certain services to fulfill orders, ship your product and host our website. In some instances, these contractors may have access to personal information but they are obligated by law to maintain confidentiality of such information and to use it only for purposes of providing the services we have requested.
                    <br/>
                    <br/>
It is our strict policy NOT to share your personal information with any third party (except as described above) for any reason. We reserve the right to disclose your personal information to appropriate third parties if we are required to do so by law.
                    </p>
                </div>
            </div>
        );
    }
}

export default FameCoffee;